<template>
  <b-card no-body>
    <SidebarSearch :components="searchComponent" />

    <TableHeader
      :selected-length="selectedDatas.length"
      :per-page="perPage"
      :has-create-button="false"
      :disable-filter="true"
      @per-page-change="perPage = $event"
    >
      <template #dropdown-items>
        <b-dropdown-item :disabled="selectedDatas.length === 0">
          <Feather-icon icon="CopyIcon" />
          Salin ke Produk Utama ({{ selectedDatas.length }})
        </b-dropdown-item>
        <b-dropdown-item :disabled="selectedDatas.length === 0">
          <Feather-icon icon="LinkIcon" />
          Tautkan SKU ({{ selectedDatas.length }})
        </b-dropdown-item>
      </template>

      <template #buttons-group>
        <!-- sinkron button -->
        <b-button variant="success" size="sm" disabled>
          <Feather-icon icon="RefreshCwIcon" />
          Sinkron Produk
        </b-button>
      </template>
    </TableHeader>

    <b-table-simple
      striped
      responsive
      style="zoom: 80%"
      class="position-relative"
    >
      <b-thead>
        <b-tr>
          <b-th class="bg-transparent" style="width: 5%">
            <b-form-checkbox v-model="selectAll" />
          </b-th>
          <b-th class="bg-transparent" style="width: 40%">Produk</b-th>
          <b-th class="bg-transparent">Harga</b-th>
          <b-th class="bg-transparent">Stok</b-th>
          <b-th class="bg-transparent">Status</b-th>
          <b-th class="bg-transparent">Terakhir diubah</b-th>
          <b-th class="bg-transparent"></b-th>
        </b-tr>
      </b-thead>
      <b-tbody v-if="tableData.length > 0">
        <b-tr v-for="(data, index) in tableData" :key="index">
          <b-td>x</b-td>
        </b-tr>
      </b-tbody>
      <EmptyTableRow v-else :colspan="7" />
    </b-table-simple>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BDropdownItem,
  BButton,
  BFormCheckbox,
  BTableSimple,
  BThead,
  BTbody,
  BTh,
  BTr,
  BTd,
} from 'bootstrap-vue';
import { defineComponent } from '@vue/composition-api';
import SidebarSearch from '@/components/SidebarSearch.vue';
import TableHeader from '@/components/TableHeader.vue';
import EmptyTableRow from '@/components/EmptyTableRow.vue';

export default defineComponent({
  name: 'MarketplaceTiktokList',
  components: {
    BRow,
    BCol,
    BCard,
    SidebarSearch,
    TableHeader,
    EmptyTableRow,
    BDropdownItem,
    BButton,
    BFormCheckbox,
    BTableSimple,
    BThead,
    BTbody,
    BTh,
    BTr,
    BTd,
  },
  async created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  async mounted() {
    // await this.fetchData();
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  data() {
    return {
      tableData: [
        // {
        //   id: 1,
        //   name: 'Product 1',
        //   price: 1000,
        //   stock: 10,
        //   status: 'Active',
        //   lastUpdated: '2021-01-01',
        // },
      ],
      currentPage: 1,
      perPage: 20,
      totalRows: 0,
      isFetching: false,
      selectedDatas: [],
      selectAll: false,
      search: '',
      productData: {},
      searchComponent: [
        {
          name: 'Pencarian Produk',
          placeholder: 'Cari produk (nama, sku)',
          type: 'text',
          value: '',
        },
        {
          label: 'Urutkan berdasarkan',
          name: 'sortBy',
          type: 'select',
          value: null,
          options: [
            { value: '1', text: 'Tanggal (Terbaru)' },
            { value: '2', text: 'Tanggal (Terlama)' },
            { value: '3', text: 'Harga (Tertinggi)' },
            { value: '4', text: 'Harga (Terendah)' },
            { value: '5', text: 'Stok (Tertinggi)' },
            { value: '6', text: 'Stok (Terendah)' },
          ],
        },
        {
          label: 'Kondisi Produk',
          name: 'condition',
          type: 'select-multiple',
          value: null,
          options: [
            { value: '1', title: 'Baru' },
            { value: '2', title: 'Bekas' },
          ],
        },
        {
          label: 'Status Produk',
          name: 'status',
          type: 'select-multiple',
          value: null,
          options: [
            { value: '1', title: 'Aktif' },
            { value: '2', title: 'Best (Feature Product)' },
            { value: '3', title: 'Tidak Aktif (Gudang)' },
            { value: '-1', title: 'Pending' },
            { value: '-2', title: 'Banned' },
            { value: '0', title: 'Terhapus' },
          ],
        },
      ],
    };
  },
});
</script>
